<script setup lang="ts">
import type { CurrencyCode, OrderDetailLineItemFragment } from '#graphql-operations'
import { upperFirst } from 'scule'
import Loading from '~/components/Loading.vue'

const props = withDefaults(
  defineProps<{
    line: OrderDetailLineItemFragment
    currency: CurrencyCode
    editable?: boolean
  }>(),
  {
    editable: true,
  },
)

const emit = defineEmits<{
  (event: 'remove:orderLine', orderLine?: OrderDetailLineItemFragment): void
  (event: 'change:orderLine', orderLine?: OrderDetailLineItemFragment, quantity?: number): void
  (event: 'click:link', orderLine?: OrderDetailLineItemFragment): void
}>()

const isLoading = ref(false)

const { t } = useI18n()

const { removeOrderLine, adjustOrderLine, getSurchargesForOrderLine } = useActiveOrder()
const priceFormatter = usePriceFormatter()

async function handleOrderLine(action: 'remove' | 'adjust', line: OrderDetailLineItemFragment, quantity?: number) {
  if (isLoading.value)
    return

  if (quantity !== undefined) {
    if (quantity < 1)
      return handleOrderLine('remove', line)
    else if (quantity > 99)
      quantity = 99
  }

  isLoading.value = true

  if (action === 'remove') {
    await removeOrderLine(line)
    emit('remove:orderLine', line)
  }
  else if (quantity !== undefined) {
    await adjustOrderLine(line, quantity)
    emit('change:orderLine', line, quantity)
  }

  isLoading.value = false
}

const orderLineSurcharges = computed(() => getSurchargesForOrderLine(props.line.id))

const saved = computed(() => {
  const { productVariant, unitPrice, quantity } = props.line
  const priceBeforeDiscount = productVariant?.priceBeforeDiscount
  if (priceBeforeDiscount && unitPrice !== priceBeforeDiscount) {
    return priceFormatter((priceBeforeDiscount - unitPrice) * quantity, props.currency)
  }
  return null
})

const { editable, line } = toRefs(props)
</script>

<template>
  <li class="relative flex flex-col list-none">
    <Loading v-if="isLoading" />

    <div class="relative flex">
      <div class="relative min-w-20 w20 overflow-hidden rd-md ring-1 ring-black/5">
        <NuxtImg
          v-if="line.featuredAsset?.preview"
          :src="line.featuredAsset.preview"
          :alt="line.productVariant.product.name"
          class="aspect-[3/4] object-cover object-center"
          width="100"
          height="100"
          loading="lazy"
        />

        <ContentGuard>
          <div class="absolute bottom-0 w-full bg-white/75 text-center text-xs c-emerald-500 fw600">
            <div class="bg-emerald/15 py0.5 backdrop-blur-sm">
              {{ t('product.in_stock').toUpperCase() }}
            </div>
          </div>
        </ContentGuard>
      </div>

      <div class="ml-4 flex-auto sm:ml-6">
        <div class="flex flex-col justify-between sm:flex-row">
          <div>
            <h3 class="text-slate-900 fw600">
              <NLink
                class="line-clamp-2 text-sm fw600 no-underline"
                :to="`/products/${line.productVariant.product.id}/${line.productVariant.product.slug}`"
                @click="$emit('click:link', line)"
              >
                {{ line.productVariant.product.name }}
              </NLink>
            </h3>

            <ContentGuard>
              <div v-if="line.productVariant.options.length" class="mt1">
                <p
                  v-for="option in line.productVariant.options"
                  :key="option.code"
                  class="c-slate5 dark:c-slate7"
                  n="xs"
                >
                  {{ upperFirst(option.group.name) }}: {{ upperFirst(option.name) }}
                </p>
              </div>
            </ContentGuard>
          </div>

          <div class="mt2 fw600 sm:mt0 sm:text-right">
            <ins class="block text-context fw600 decoration-none" n="slate9 dark:slate4">
              {{ priceFormatter(line.linePrice, props.currency) }}
            </ins>
            <del
              v-if="line.productVariant?.priceBeforeDiscount && line.unitPrice !== line.productVariant.priceBeforeDiscount"
              class="text-context line-through" n="slate5 sm"
            >
              {{ priceFormatter(line.productVariant?.priceBeforeDiscount * line.quantity, props.currency) }}
            </del>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editable" class="mt2 flex items-center justify-between">
      <div class="space-x-2">
        <a
          class="text-context fw600"
          n="sm orange6 dark:orange4 hover:orange5"
          href=""
          @click.prevent="handleOrderLine('remove', line)"
        >
          {{ t('general.remove.label') }}
        </a>
        <span class="mt1 c-slate5 dark:c-slate7" n="xs">
          {{ t('product.n_per_item.label', [priceFormatter(line.unitPrice, props.currency)]) }}
        </span>
      </div>

      <div class="relative inline-block">
        <div
          data-testid="quantityStepper"
          class="w30 flex flex-row justify-between border n-border-300 rd-full bg-white p1 text-base n-text-content"
        >
          <button
            type="button"
            class="h6 w6 flex cursor-pointer items-center justify-center rd-4 border-none bg-white p0 hover:bg-slate-100"
            aria-disabled="false"
            aria-label="Decrease quantity"
            @click="handleOrderLine('adjust', line, line.quantity - 1)"
          >
            <NIcon icon="i-heroicons:minus-16-solid" />
          </button>

          <span class="mx-auto flex items-center text-sm fw700 n-text-heading">
            {{ line.quantity }}
          </span>

          <button
            type="button"
            class="h6 w6 flex cursor-pointer items-center justify-center rounded-4 border-none bg-white p0 hover:bg-slate-100"
            aria-disabled="false"
            aria-label="Increase quantity"
            @click="handleOrderLine('adjust', line, line.quantity + 1)"
          >
            <NIcon icon="i-heroicons:plus-16-solid" />
          </button>
        </div>
      </div>
    </div>

    <div v-if="orderLineSurcharges?.length" class="mt4">
      <div v-for="(surcharge, index) in orderLineSurcharges" :key="index" class="mt2 border n-border-base rd-lg px4 py1">
        <div class="flex items-center justify-between text-sm">
          <span class="inline-flex items-center">
            {{ surcharge.details.label }}
            <NTooltip v-if="surcharge.details.tooltip">
              <template #toggle>
                <NIcon icon="i-heroicons:information-circle-solid" class="ml2 c-slate/75" />
              </template>
              {{ surcharge.details.tooltip }}
            </NTooltip>
          </span>
          <span>
            {{ priceFormatter(surcharge.price, props.currency) }}
          </span>
        </div>
      </div>
    </div>
  </li>
</template>
